// 3rd-party Plugins
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Theme Styles
@import './_metronic/_assets/sass/style.react.scss';

// Default Layout Skins
@import './_metronic/_assets/sass/global/layout/header/skins/base/light.scss';
@import './_metronic/_assets/sass/global/layout/header/skins/menu/light.scss';
@import './_metronic/_assets/sass/global/layout/brand/skins/dark.scss';
@import './_metronic/_assets/sass/global/layout/aside/skins/dark.scss';

// Header Skins
.kt-header-base-light {
  @import './_metronic/_assets/sass/global/layout/header/skins/base/light.scss';
}

.kt-header-base-dark {
  @import './_metronic/_assets/sass/global/layout/header/skins/base/dark.scss';
}

// Header Menu Skins
.kt-header-menu-light {
  @import './_metronic/_assets/sass/global/layout/header/skins/menu/light.scss';
}
.kt-header-menu-dark {
  @import './_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss';
}

// Brand Skins
.kt-brand-dark {
  @import './_metronic/_assets/sass/global/layout/brand/skins/dark.scss';
}

.kt-brand-light {
  @import './_metronic/_assets/sass/global/layout/brand/skins/light.scss';
}

// Aside skins
.kt-aside-dark {
  @import './_metronic/_assets/sass/global/layout/aside/skins/dark.scss';
}

.kt-aside-light {
  @import './_metronic/_assets/sass/global/layout/aside/skins/light.scss';
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

@-webkit-keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpContent {
  -webkit-animation-name: fadeInUpContent;
  animation-name: fadeInUpContent;
}

.dawa-autocomplete-suggestions {
  margin: 0.3em 0 0 0;
  padding: 0;
  text-align: left;
  border-radius: 0.3125em;
  background: #fcfcfc;
  box-shadow: 0 0.0625em 0.15625em rgba(0, 0, 0, 0.15);
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9999;
  overflow-y: auto;
  box-sizing: border-box;
}

.dawa-autocomplete-suggestions .dawa-autocomplete-suggestion {
  margin: 0;
  list-style: none;
  cursor: pointer;
  padding: 0.4em 0.6em;
  color: #333;
  border: 0.0625em solid #ddd;
  border-bottom-width: 0;
}

.dawa-autocomplete-suggestions .dawa-autocomplete-suggestion:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.dawa-autocomplete-suggestions .dawa-autocomplete-suggestion:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  border-bottom-width: 0.0625em;
}

.dawa-autocomplete-suggestions .dawa-autocomplete-suggestion.dawa-selected,
.dawa-autocomplete-suggestions .dawa-autocomplete-suggestion:hover {
  background: #f0f0f0;
}
